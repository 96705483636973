<template>
<div>
    <myhead :param="param" />
    <section>
        <div class="bannergrlamp">
            <div style="height:280px; "></div>
            <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>

        </div>
    </section>
    <section style="background-color: #f3f3f3">
        <div class="htable" style="height: 64px;justify-content:center;align-items:center;font-size: 16px;">
            <div class="hcell" style="padding-right:42px;border-right: 1px solid rgba(20,20,20,0.7);font-weight: bold;">智慧路灯</div>
            <div v-if="termequi && termequi.length>0 " class="hcell" style="margin-left: 42px;"><span class="mgd" @click="goitem('termequi')">智慧路灯终端设备</span></div>
            <div v-if="smaupsrt && smaupsrt.length>0 " class="hcell" style="margin-left: 80px;"><span class="mgd" @click="goitem('smaupsrt')">智慧路灯机房设备</span></div>
            <div v-if="trstequi && trstequi.length>0 " class="hcell" style="margin-left: 80px;"><span class="mgd" @click="goitem('trstequi')">智慧路灯中转站设备</span></div>
            <div v-if="wisdscheme && wisdscheme.length>0 " class="hcell" style="margin-left: 80px;"><span class="mgd" @click="goitem('wisdscheme')">应用方案</span></div>
        </div>
    </section>

    <section style="margin-top: 90px;" id="termequi" v-if="termequi && termequi.length>0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">智慧</span>路灯终端<br/>设备</div>
            </div>
            <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                <div class="htable" style="justify-content: space-between;">
                    <cpblock_two :param="termequi[0]" />
                    <cpblock_one :param="termequi[1]"  />

                </div>
                <div style="height: 45px;"></div>
            </div>
            <div class="hcell" style="width: 130px;"></div>
        </div>
    </section>

    <section style="margin-top: 45px;" id="smaupsrt" v-if="smaupsrt && smaupsrt.length>0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">智慧</span>路灯机房<br/>设备</div>
            </div>
            <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                <div class="htable" style="justify-content: space-between;">
                    <cpblock_one :param="smaupsrt[0]"  />
                    <cpblock_one :param="smaupsrt[1]"  />
                    <cpblock_one :param="smaupsrt[2]"  />
                </div>
                <div style="height: 45px;"></div>
            </div>
            <div class="hcell" style="width: 130px;"></div>

        </div>
    </section>

    <section style="margin-top: 45px;" id="trstequi" v-if="trstequi && trstequi.length>0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">智慧</span>路灯中转<br/>设备</div>
            </div>
            <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                <div class="htable" style="justify-content: space-between;">
                    <cpblock_two :param="trstequi[0]" />
                    <cpblock_one :param="trstequi[1]"  />


                </div>
                <div style="height: 45px;"></div>
            </div>
            <div class="hcell" style="width: 130px;"></div>
        </div>
    </section>

    <section style="margin-top: 45px;" id="wisdscheme" v-if="wisdscheme && wisdscheme.length>0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">应用</span>方案</div>
            </div>
            <div class="hcell" style="width: 1400px;">
                <div class="htable" style="justify-content: space-between;">
                    <scheme_one :param="wisdscheme[0]"  />
                    <scheme_one :param="wisdscheme[1]"  />

                </div>
                <div class="htable" style="justify-content: space-between;margin-top: 25px;" v-if="wisdscheme[2]">
                    <scheme_one :param="wisdscheme[2]"  />
                    <scheme_one :param="wisdscheme[3]"  />


                </div>

            </div>
            <div class="hcell" style="width: 130px;"></div>

        </div>
    </section>

    <section style="height: 90px;"></section>
</div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import { listTopicBases } from "../hts/api.js"
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    import scheme_one  from  '../components/scheme_one.vue'
    import mydata from '../static/data/zhlamp.json'
    const param = {
        pageindex: 2,
        rows: 6,
        page: 1,
    }
    export default {
        name: "gflamp" ,
        components: {
            myhead,
            cpblock_two,
            cpblock_one,
            scheme_one,

        },
        data() {
            return {
                param,
                termequi: mydata.termequi,
                smaupsrt: mydata.smaupsrt,
                trstequi: mydata.trstequi,
                wisdscheme: mydata.wisdscheme,
            }
        },
        mounted() {
           // this.getList()
        },
        methods: {
            getList () {
                // console.log(this.id)
                listTopicBases({type:'zhlamp:[termequi,smaupsrt,trstequi,wisdscheme]', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        this.termequi=resData.data.termequi
                        this.smaupsrt=resData.data.smaupsrt
                        this.trstequi=resData.data.trstequi
                        this.wisdscheme=resData.data.wisdscheme
                    })
                    .catch()
            },
            goitem(id){
                document.getElementById(id).scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                });
            },
            goscheme(id) {
                this.$router.push({ path: 'scheme', query:{id:id} });
            },
        },
    }
</script>

<style scoped>
    .bannergrlamp{
        background-image: url('~assets/zhlamp_banner.jpg');
        height: 600px;
    }
    .mgd {
        cursor:pointer;
        padding: 0 5px;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        /*border-radius: 5px;*/
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;
    }
    .newimg{
        height: 290px;
        width: 450px;
        display: block;
    }
    .newtitle{
        height: 25px;
        overflow: hidden;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 25px;
    }
    .newnote{
        font-size: 16px;
        color: rgba(0,0,0,0.8);
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        line-height: 28px;
        margin-top: 15px;
        height:85px;
        overflow: hidden;
    }
</style>
